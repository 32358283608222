const getApiRoot = () => {
  const hashTag = window.location.hash;
  let apiRoot = hashTag.includes('#remote')?'https://api-dev.mustangbus.com':"http://localhost:3001";
  hashTag === 'localhost'

  if (window.location.hostname.includes("reservations.mustangbus.com")) {//no prefix
    apiRoot = "";
  } else if (window.location.hostname.includes("reservations-test.mustangbus.com")) {//no prefix    
    apiRoot = "";
  } 
  return apiRoot;
};

export { getApiRoot };
