import React from "react";
import { Navigate } from "react-router-dom";
import Login from "./Login.js";

const Logout = ({ authorization }) => {
  document.cookie = "oAuthCode=" + ""; //we want a new cookie, so force google to let us log in again
  window.location.href = "/login";
  return (
    <Navigate to="/" element={<Login authorization={authorization}/>} />
  );
};

export default Logout;
