// Modal.js
import React, { useState } from "react";
import Select from "react-select";
import { customFetch } from "../utils/customFetch.js";
import { getApiRoot } from "../utils/getApiRoot.js";
import "./addcustomermodal.css";
const apiRoot = getApiRoot();

const Modal = ({ showModal, closeModal }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    disableCompanyName: false,
    contract: false,
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: ""
  });

  const stateOptions = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "DC", label: "District of Columbia" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleStateChange = (selectedOption) => {
    setFormData((prevData) => ({ ...prevData, state: selectedOption.value }));
  };

  const handleCheckboxChange = () => {
    setFormData((prevData) => ({ ...prevData, disableCompanyName: !prevData.disableCompanyName }));
  };

  const handleCustomerTypeChange = (contract) => {
    setFormData((prevData) => ({ ...prevData, contract }));
  };

  const handleAddCustomer = async () => {
    // Add your logic here to handle adding a customer with formData
    // You can make an API call, update state, etc.
    let options = {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    };
    const addCustomerResponse = await customFetch(`${apiRoot}/api/customers`, options);
    console.log("Adding customer:", formData);
    if (addCustomerResponse.status == 409) {
      alert("A customer with the phone number or email already exists");
    } else if (addCustomerResponse.status == 200) {
      closeModal();
    } else {
      alert(`${addCustomerResponse.status} Error adding customer.`);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #c2c2c2",
      borderRadius: "8px"
    }),

    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
      border: "1px solid #c2c2c2",
      maxHeight: "200px",
      overflowY: "auto"
    }),

    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#888" : "white",
      ":hover": {
        backgroundColor: "#f0f0f0"
      },
      overflow: "hidden"
    })
  };

  return (
    <div className={`modal-overlay ${showModal ? "show" : "hide"}`} onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="ACmodal-header">
          <div></div>
          <h2>ADD CUSTOMER</h2>
          <button className="modal-close-btn" onClick={closeModal}>
            X
          </button>
        </div>
        <div className="ACmodal-body">
          <div className="row">
            <label>
              First Name:
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Last Name:
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="row">
            <label>
              Email:
              <input
                type="text"
                name="email"
                placeholder="example@email.com"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={() => {
                  console.log("Email lost focus");
                }}
              />
            </label>
            <label>
              Phone Number:
              <input
                type="text"
                name="phoneNumber"
                placeholder="(XXX) XXX - XXXX"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </label>
          </div>
          {/* Add the new address-related fields */}
          <div className="row">
            <label>
              Address Line 1:
              <input
                type="text"
                name="addressLine1"
                placeholder="Address Line 1"
                value={formData.addressLine1}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Address Line 2:
              <input
                type="text"
                name="addressLine2"
                placeholder="Address Line 2"
                value={formData.addressLine2}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="row" id="three-row">
            <label>
              City:
              <input
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleInputChange}
              />
            </label>
            <label>
              State:
              <Select
                defaultValue={formData.state}
                onChange={handleStateChange}
                options={stateOptions}
                placeholder="Select State"
                styles={customStyles}
              />
            </label>
            <label>
              Zip Code:
              <input
                type="text"
                name="zipCode"
                placeholder="Zip Code"
                value={formData.zipCode}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="row">
            <label>
              Company:
              <div className="company">
                <input
                  type="text"
                  className="companyNameInput"
                  name="companyName"
                  placeholder="Company Name"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  disabled={formData.disableCompanyName}
                />
                <div className="companyDiv">
                  <input
                    type="checkbox"
                    className="companyCheckbox"
                    checked={formData.disableCompanyName}
                    onChange={handleCheckboxChange}
                  />
                  <div className="noCompany"> No Company</div>
                </div>
              </div>
            </label>
          </div>
          <div className="row">
            <div>
              Type of Customer:
              <div id="rowTypeSelect">
                <label className="typeSelect">
                  <input
                    type="radio"
                    name="customerType"
                    value="contract"
                    className="radio"
                    checked={formData.contract}
                    onChange={() => handleCustomerTypeChange(true)}
                  />
                  Contract
                </label>
                <label className="typeSelect">
                  <input
                    type="radio"
                    name="customerType"
                    value="non-contract"
                    className="radio"
                    checked={!formData.contract}
                    onChange={() => handleCustomerTypeChange(false)}
                  />
                  Non-Contract
                </label>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="button-CANCEL" onClick={closeModal}>
            CANCEL
          </button>
          <button className="button-ADD" onClick={handleAddCustomer}>
            ADD CUSTOMER
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
