import React, { useState } from "react";
import AddCustomerModal from "./addcustomermodal";
import classes from "./header.module.css";
import TripModal from "./tripmodal"; // Import the new modal component
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const TripComponent = ({
  resetTripModal,
  trips,
  setTrips,
  tripModalData,
  setTripModalData,
  showTripModal,
  setShowTripModal,
}) => {
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  const handlePrint = () => {
    const input = document.getElementById("table-to-print");
    const imageHeight= input.offsetHeight*.8;
    const imageWidth = input.offsetWidth*1.4;//increasing the imageWidth makes the image size wider so it can fit the bigger window below
    const windowWidth = input.scrollWidth*1.7;//increasing the windowWidth prevents the text from wrapping
    html2canvas(input, {
      scale: 2, // Increase the resolution for better clarity
      scrollX: 0,
      scrollY: 0,
      width: imageWidth, // Use offsetWidth to capture the full width, don't cut side off
      height: imageHeight, // Use offsetHeight to capture the full height, don't cut end off
      windowWidth, // Ensure the full content width is captured
      windowHeight: input.scrollHeight, // Ensure the full content height is captured
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
  
    // // Download the image for troubleshooting
    // const link = document.createElement("a");
    // link.href = imgData;
    // link.download = "table-screenshot.png";
    // link.click();
    
    // Create a PDF in landscape mode
      const pdf = new jsPDF("landscape", "mm", "a4");
  
      const imgWidth = 297; // A4 width in landscape mode (mm)
      const pageHeight = 210; // A4 height in landscape mode (mm)
      const imgHeight = imgWidth * canvas.height/ canvas.width;//use the canvas proportions to calculate the height
      console.log('Image Height', imgHeight)
      let heightLeft = imgHeight;
  
      let position = 0;
  
      // Add the first page
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      console.log('Height Left', heightLeft);
  
      // Add additional pages if needed
      while (heightLeft > 0) {
        position = heightLeft - imgHeight; // Move to the next page
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      // Get the current date and time
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      // Format the date and time
      const formattedDateTime = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

      // Construct the filename
      const filename = `bus-reservations_${formattedDateTime}.pdf`;

      // Save the PDF with the constructed filename
      pdf.save(filename);
    });
  };
    
  const handleOpenAddCustomerModal = () => {
    console.log("Opening Add Customer Modal...");
    setShowAddCustomerModal(true);
  };

  const handleShowTripModal = () => {
    console.log("Opening Create Quote Modal...");
    setShowTripModal(true);
  };

  const closeModal = () => {
    resetTripModal();
    setShowAddCustomerModal(false);
    setShowTripModal(false);
  };

  const openAddCustomerModal = () => {
    setShowAddCustomerModal(true);
  };

  return (
    <div className={classes.header}>
      <h2 className={classes.headerH2}>Trips</h2>
      <div className={classes.headerButtons}>
        <button
          onClick={handleOpenAddCustomerModal}
          className={classes.headerAddCustomer}
        >
          ADD CUSTOMER
        </button>
        <button
          onClick={handleShowTripModal}
          className={classes.headerCreateQuote}
        >
          CREATE QUOTE
        </button>
        <button onClick={handlePrint} className={classes.headerPrintButton}>
          PRINT
        </button> 
      </div>

      {/* Render the Add Customer Modal */}
      {showAddCustomerModal && (
        <AddCustomerModal
          showModal={showAddCustomerModal}
          closeModal={closeModal}
        />
      )}

      {/* Render the Create Quote Modal */}
      {showTripModal && (
        <TripModal
          resetTripModal={resetTripModal}
          trips={trips}
          setTrips={setTrips}
          tripModalData={tripModalData}
          setTripModalData={setTripModalData}
          showTripModal={showTripModal}
          openAddCustomerModal={openAddCustomerModal}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default TripComponent;
