import { googleLogout } from "@react-oauth/google";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login.js";
import Logout from "./components/Logout.js";
import NonContract from "./components/non-contract.js";
import Payment from "./components/payment.js";
import Trips from "./components/Trips.js";
import { getCookie } from "./utils/getCookie.js";
import { extractTripUrlData, extractUrlData } from "./utils/getUrlData.js";
import { AuthProvider, useAuth } from './context/AuthContext'; // Import the provider

const App = () => {
  const { authorization } = useAuth(); // Access the authorization from context
  const [trips, setTrips] = useState([]);
  const { route, tripId, token } = extractTripUrlData();
  const urlParams = extractUrlData();

  const isIdAndTokenValid = tripId && token;
  const isInPaymentRoute = route.includes("non-contract/payment");
  const isNonContractRoute = route === "/non-contract";

  const renderRoute = () => {
    if (urlParams.code) {
      document.cookie = `oAuthCode=${urlParams.code}`;
    }
    return authorization ? (
      <Trips trips={trips} setTrips={setTrips}/>
    ) : (
      <Login />
    );
  };


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={renderRoute()} />
        <Route path="/non-contract/*" element={<NonContract />} />
        <Route path="/logout" element={<Logout googleLogout={googleLogout} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/payment" element={<Payment />} />
      </Routes>
    </BrowserRouter>
  );
};

// Wrap the App component with AuthProvider
const WrappedApp = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default WrappedApp;
