export const extractUrlData = () => {
  // Get the full URL from the browser
  const url = window.location.href;

  // Create a URL object
  const urlObj = new URL(url);

  // Use URLSearchParams to get query parameters
  const params = new URLSearchParams(urlObj.search);
  let returnParams = {};
  params.forEach((value, key) => {
    returnParams[key] = value;
  });
  return returnParams;
}


export const extractTripUrlData = () => {
  const route = window.location.pathname;
  const hash = window.location.hash;
  const hashParts = hash.split("#");
  const [tripId, rest] = hashParts[1]?.split("?") || [];
  const token = rest?.split("=")[1];

  return { route, tripId, token };
};


