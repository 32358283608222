import PropTypes from "prop-types";
import React, { useState } from "react";
import viewedEye from "./images/eyeRegular.svg";
import notViewedYetEye from "./images/eyeSlashRegular.svg";
import editIcon from "./images/penToSquareRegular.svg";
import classes from "./table.module.css";

const TableComponent = ({ currentPage, setCurrentPage, setTripModalData, setShowTripModal, trips }) => {
  const ITEMS_PER_PAGE = 8;
  const totalPages = Math.ceil(trips.length / ITEMS_PER_PAGE);

  let startIndex=0;
  let endIndex=0;
  if(trips.length>0){
    startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    endIndex = Math.min(startIndex + ITEMS_PER_PAGE, trips.length);
    
  }

  const showingStart = trips.length === 0 ? 0 : startIndex + 1;
  const showingEnd = trips.length === 0 ? 0 : endIndex;

  const currentRows = trips.slice(startIndex, endIndex);

  return (
    <div id="table-to-print" className={classes.table}>
      <table className={classes.tableTable}>
        <thead className={classes.tableThead}>
          <tr className={classes.tableTr}>
            <th>QUOTE NUMBER</th>
            <th>DRIVER</th>
            <th>BUS</th>
            <th>DEPART</th>
            <th>RETURN</th>
            <th>ORIGIN</th>
            <th>DESTIN</th>
            <th>BUS</th>
            <th>NOTES</th>
            <th>COMPANY</th>
            <th>TRIP</th>
            <th>PAYMENT</th>
            <th>CLIENT</th>
            <th>VIEWED</th>
            <th>EDIT</th>
          </tr>
        </thead>
        <tbody className={classes.tableTbody}>
          {currentRows.map((row) => (
            <tr key={row.tripId} className={classes.tableTr}>
              <td>{row.tripId}</td>
              <td>{row.driverName}</td>
              <td>{row.busName}</td>
              <td>{row.departureDate.toString()}</td>
              <td>{row.returnDate.toString()}</td>
              <td>{row.pickupLocation}</td>
              <td>{row.destination}</td>
              <td>{row.busName}</td>
              <td>{row.notes}</td>
              <td>{row.companyName}</td>
              <td>{row.tripStatus}</td>
              <td>{row.paymentStatus}</td>
              <td>{`${row.firstName} ${row.lastName}`}</td>
              <td>
                <img
                  src={row.viewed ? viewedEye : notViewedYetEye}
                  alt={row.viewed ? "viewed" : "Unopened"}
                  style={{
                    opacity: row.viewed ? 1 : 0.5,
                    filter: row.viewed
                      ? "invert(35%) sepia(100%) hue-rotate(90deg) saturate(300%)"
                      : "none",
                  }}
                />
              </td>
              <td>
                <button
                  onClick={() => {
                    setTripModalData(row);
                    setShowTripModal(true);
                    console.log(`Row Clicked: ${JSON.stringify(row)}`);
                  }}
                  className={classes.editButton}
                >
                  <img src={editIcon} alt="Edit" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={classes.tableBottom}>
        <p>
          Showing {showingStart} to {Math.min(showingEnd, trips.length)} of{" "}
          {trips.length} results
        </p>
        <div>
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            className={classes.prevButton}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 18L8 12L16 6"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={
                currentPage === index + 1 ? classes.buttonhighlight2 : ""
              }
              onClick={() => setCurrentPage(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
            className={classes.nextButton}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 6L16 12L8 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

      </div>
    </div>
  );
  
};

export default TableComponent;

TableComponent.propTypes = {
  trips: PropTypes.array.isRequired,
  setTripModalData: PropTypes.func.isRequired,
  setShowTripModal: PropTypes.func.isRequired,
};
