export const convertNumberToCurrency = (value) => {
  // Ensure the input is converted to a number to handle both string and number inputs.
  const numericValue = Number(value);
  // Check if the conversion to number was successful and the value is not NaN.
  if (!isNaN(numericValue)) {
    // Use Intl.NumberFormat to format the number as US currency.
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
      numericValue
    );
  } else {
    // Return a warning message if the input cannot be converted to a number.
    return "Invalid input: Please provide a valid number or numeric string.";
  }
};
