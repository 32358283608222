// src/components/Login.js
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { customFetch } from "../utils/customFetch.js";
import { getApiRoot } from "../utils/getApiRoot.js";
import { getCookie } from "../utils/getCookie.js";
import logo from "./images/wholeLogo.webp";
import classes from "./login.module.css";
import { useAuth } from '../context/AuthContext'; // Import the custom hook

function Login() {
  const { authorization, setAuthorization } = useAuth(); // Use the custom hook
  const domainWithPort = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

  useEffect(() => {
    const cookie = getCookie("oAuthCode");
    const auth = async () => {
      if (!cookie) {
        try {
          const client = window.google.accounts.oauth2.initCodeClient({
            client_id: "257921541428-mj6719g80bei7sqe6dshso09v3nn99m3.apps.googleusercontent.com",
            scope: "https://www.googleapis.com/auth/calendar.readonly",
            ux_mode: "redirect",
            redirect_uri: domainWithPort,
            // state: "YOUR_BINDING_VALUE",
            access_type: "offline",
          });
          client.requestCode();
        } catch (error) {
          console.error("Error using Google Login: ", error);
        }
        return;
      } else if (!authorization) {
        const apiRoot = getApiRoot();
        let options = { credentials: "include" };
        const authorizationResponse = await customFetch(`${apiRoot}/api/token`, options);
        setAuthorization(await authorizationResponse.json());
      }
    };
    auth();
  }, [authorization, setAuthorization]);

  if (!authorization) {
    return (
      <div className={classes.login}>
        <div className={classes.loginImage}>
          <img src={logo} alt="Mustang Transportation logo" className={classes.loginLogo} />
        </div>
        <div className={classes.loginFlex}>
          <div className={classes.loginFlexSection}>
            <h2 className={classes.loginH2}>WELCOME</h2>
            <p className={classes.loginBodyText}>Login with your Mustang account</p>
            <p className={classes.loginBodyText}>
              Payment service is provided by Intuit Payments Inc.
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
}

export default Login;
