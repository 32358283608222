import React, { useEffect, useState } from "react";
import { customFetch } from "../utils/customFetch.js";
import { getApiRoot } from "../utils/getApiRoot.js";
import DatePicker from "react-datepicker";
import classes from "./filter.module.css";

const defaultTripDateRange={departureDateFrom: "", departureDateTo: "",returnDateFrom: "",returnDateTo:""}

const FilterComponent = ({ 
  currentPage, 
  setCurrentPage, 
  setTrips 
}) => {
  const [filter1Type, setFilter1Type] = useState("lastName");
  const [filter1Text, setFilter1Text] = useState("");
  const [filter2, setFilter2] = useState("tripStatus");
  const [filter3, setFilter3] = useState("paymentStatus");
  const[tripDateRange, setTripDateRange] = useState(defaultTripDateRange);

  const clearAllFilters = () => {
    setCurrentPage(1);//they changed the filter, so we reset the page to 1
    //if they click the clear all filters button
    setFilter1Type("lastName");
    setFilter1Text("");
    setFilter2("tripStatus");
    setFilter3("paymentStatus");
    setTripDateRange(defaultTripDateRange);
    setTrips([]);
  };

  // TODO: in this useEffect we need to call the searchTrips endpoint, and find the trip that is needed for what the user entered into for the search criteria
  useEffect(() => {
    const searchTrips = async () => {
      const queryParams = {};//something changed in the filter, so we reconstruct from nothing

      filter1Text && (queryParams[filter1Type] = filter1Text); //first we check filter1 to see if they selected anything and add that to the query

      filter2 != "tripStatus" && (queryParams.tripStatus = filter2); //next, if selected, we add the tripStatus to the query

      filter3 != "paymentStatus" && (queryParams.paymentStatus = filter3); //next, if selected, we add the paymentStatus to the query

      if (tripDateRange.departureDateFrom && tripDateRange.departureDateTo) {//if they selected a departure date range we add that to the query
        queryParams.departureDateMillisFrom = new Date(tripDateRange.departureDateFrom).getTime();//the calendar defaults to midnight, so we keep midnight for this
        queryParams.departureDateMillisTo = new Date(tripDateRange.departureDateTo).getTime()+24*60*60*1000-1;//we will use 11:59:59 PM for the to date
      }
      if (tripDateRange.returnDateFrom && tripDateRange.returnDateTo) {// if they selected a return date range we add that to the query
        queryParams.returnDateMillisFrom = new Date(tripDateRange.returnDateFrom).getTime();//the calendar defaults to midnight, so we keep midnight for this
        queryParams.returnDateMillisTo = new Date(tripDateRange.returnDateTo).getTime()+24*60*60*1000-1;//we will use 11:59:59 PM for the to date
      }


      const queryString = new URLSearchParams(queryParams).toString();

      if (queryString) {
        const options = {
          credentials: "include",
          headers: {
            "content-type": "application/json"
          },
          method: "GET"
        };

        const response = await customFetch(
          getApiRoot() + `/api/trips/search?${queryString}`,
          options
        );

        if (response.ok) {
          console.log(`Query String ${queryString}`);
          let result = await response.json();
          result = result
            .map((item) => ({
              ...item,
              quoteDate: new Date(item.quoteDate),//convert strings to JavaScript Dates
              departureDate: new Date(item.departureDate),//convert strings to JavaScript Dates
              returnDate: new Date(item.returnDate)//convert strings to JavaScript Dates
            }))
            .sort((a, b) => b.quoteDate - a.quoteDate);
          setCurrentPage(1);//they changed the filter, so we reset the page to 1

          setTrips(result);

          // Handle the result, update the UI, etc.
          console.log("fetched, then secondary filtered result ", result);
        } else {
          // Handle error
          console.error("Error searching for trips:", response.statusText);
        }
      }
    };

    searchTrips();

  }, [filter1Type, filter1Text, filter2, filter3, tripDateRange]);

  return (
    <div className={classes.filter}>
      <div className={classes.filterSectionOne}>
        <select
          value={filter1Type}
          onChange={(e) => setFilter1Type(e.target.value)}
          className={classes.filterTrips}>
          <option value="lastName">Customer Last Name</option>
          <option value="driverName">Driver Name</option>
          <option value="busName">Bus Name</option>
          <option value="tripNumber">Quote Number</option>
          <option value="companyName">Company Name</option>
          <option value="groupLeader">Group Leader</option>
          <option value="groupName">Group Name</option>
          <option value="phone">Reservation Phone</option>
          <option value="email">Reservation Email</option>
          
        </select>
        <input
          type="text"
          placeholder="Search"
          value={filter1Text}
          onChange={(e) => setFilter1Text(e.target.value)}
          className={classes.filter}
        />
      </div>
      <div className={classes.filterSectionTwo}>
        <select
          value={filter2}
          onChange={(e) => setFilter2(e.target.value)}
          className={classes.filterQuoteStatus}>
          <option value="tripStatus">Quote Status</option>
          <option value="Draft">Draft</option>
          <option value="Pending">Pending</option>
          <option value="Accepted">Accepted</option>
          <option value="Cancelled">Cancelled</option>
          <option value="Rejected">Rejected</option>
          <option value="Complete">Complete</option>
        </select>
      </div>
      <div className={classes.filterSectionThree}>
        <select
          value={filter3}
          onChange={(e) => setFilter3(e.target.value)}
          className={classes.filterPaymentStatus}>
          <option value="paymentStatus">Payment Status</option>
          <option value="Unpaid">Unpaid</option>
          <option value="Deposit">Deposit</option>
          <option value="Paid">Paid</option>
          <option value="Refunded">Refunded</option>
        </select>
      </div>
      <div className={classes.dateFilterLabels}>Depart From</div>
      <div className={classes.filterDates}>
      <DatePicker
                  selected={tripDateRange.departureDateFrom}
                  onChange={(date) =>
                    setTripDateRange((prevData) => ({
                      ...prevData,
                      departureDateFrom: date
                    }))
                  }
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Date"
                    // className={classes.customDatePicker}
                />
      </div>    
      <div className={classes.dateFilterLabels}>  to  </div>
      <div className={classes.filterDates}>
      <DatePicker
                  selected={tripDateRange.departureDateTo}
                  onChange={(date) =>
                    setTripDateRange((prevData) => ({
                      ...prevData,
                      departureDateTo: date
                    }))
                  }
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Date"
                />
      </div>      
      <div className={classes.dateFilterLabels}>Return From</div>          
      <div className={classes.filterDates}>
      <DatePicker
                  selected={tripDateRange.returnDateFrom}
                  onChange={(date) =>
                    setTripDateRange((prevData) => ({
                      ...prevData,
                      returnDateFrom: date
                    }))
                  }
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Date"
                />
      </div>    
      <div className={classes.dateFilterLabels}>to</div>
      <div className={classes.filterDates}>
      <DatePicker
                  selected={tripDateRange.returnDateTo}
                  onChange={(date) =>
                    setTripDateRange((prevData) => ({
                      ...prevData,
                      returnDateTo: date
                    }))
                  }
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Date"
                />
      </div>            
      <button onClick={clearAllFilters} className={classes.filterClearAll}>
        Clear All Filters
      </button>
    </div>
  );
};

export default FilterComponent;
