// InvoiceSection.js
import React from "react";
import "./invoicesection.css";

const InvoiceSection = ({ tripModalData, setTripModalData }) => {
  const [invoiceItems, setInvoiceItems] = React.useState(
    tripModalData.invoiceItems
      ? tripModalData.invoiceItems
      : [
          {
            item: "",
            description: "",
            quantity: 0,
            unitPrice: 0,
            totalPrice: 0,
          },
          {
            item: "",
            description: "",
            quantity: 0,
            unitPrice: 0,
            totalPrice: 0,
          },
          // You can add more initial rows as needed
        ]
  );

  const calculateTotalPrice = (quantity, unitPrice) => {
    return quantity * unitPrice;
  };

  const handleInvoiceItemChange = (index, field, value) => {
    const updatedItems = [...invoiceItems];
    updatedItems[index][field] = value;

    // Update the total price when quantity or unit price changes
    if (field === "quantity" || field === "unitPrice") {
      updatedItems[index].totalPrice = calculateTotalPrice(
        updatedItems[index].quantity,
        updatedItems[index].unitPrice
      );
    }
    setTripModalData({ ...tripModalData, invoiceItems: updatedItems });
    setInvoiceItems(updatedItems);
  };

  const addInvoiceItem = () => {
    setInvoiceItems((prevItems) => [
      ...prevItems,
      { item: "", description: "", quantity: 0, unitPrice: 0, totalPrice: 0 },
    ]);
  };

  const removeInvoiceItem = (index) => {
    setInvoiceItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  const calculateSubtotal = () => {
    return invoiceItems.reduce((total, item) => total + item.totalPrice, 0);
  };

  const calculateSalesTax = (subtotal, taxRate) => {
    return (subtotal * taxRate) / 100;
  };

  const calculateGrandTotal = (subtotal, salesTax) => {
    return subtotal + salesTax;
  };

  const renderDescription = (row, index) => {
    const descriptions = {
      mileage: "Enter the total miles and the price for each mile",
      driverWage: "Enter the hours and the wage per hour",
      perDiem: "Total expense for the trip",
      accommodations: "Accommodations",
      flatRate: "Enter the flat rate for the trip",
      downDay: "Enter days you rent",
      oneDay: "It's one day rental",
      halfDay: "It's half day rental",
      hourly: "Enter hours and price for an hour",
    };

    if (row.item === "other") {
      return (
        <input
          type="text"
          placeholder="Enter a description"
          value={row.description}
          onChange={(e) =>
            handleInvoiceItemChange(index, "description", e.target.value)
          }
        />
      );
    }

    return descriptions[row.item] || "Please choose an item";
  };

  const renderInvoiceItems = () => {
    return invoiceItems.map((row, index) => (
      <div key={index} className="invoice-row">
        <select
          value={row.item}
          onChange={(e) =>
            handleInvoiceItemChange(index, "item", e.target.value)
          }
        >
          <option value="" selected disabled hidden>
            Choose here
          </option>
          <option value="mileage" selected="selected">
            Mileage
          </option>
          <option value="driverWage">Driver Wage</option>
          <option value="perDiem">Per Diem</option>
          <option value="accommodations">Accommodations</option>
          <option value="flatRate">Flat Rate</option>
          <option value="downDay">Down day rental</option>
          <option value="oneDay">One day rental</option>
          <option value="halfDay">Half day rental</option>
          <option value="hourly">Hourly rental</option>
          <option value="other">Other</option>
        </select>

        <div>{renderDescription(row, index)}</div>

        <input
          type="number"
          min="0"
          placeholder="Quantity"
          value={row.quantity}
          onChange={(e) =>
            handleInvoiceItemChange(
              index,
              "quantity",
              parseFloat(e.target.value)
            )
          }
        />
        <input
          type="number"
          min="0"
          placeholder="Unit Price"
          value={row.unitPrice}
          onChange={(e) =>
            handleInvoiceItemChange(
              index,
              "unitPrice",
              parseFloat(e.target.value)
            )
          }
        />
        <span>${row.totalPrice.toFixed(2)}</span>
        <button
          className="delete-row-button"
          onClick={() => removeInvoiceItem(index)}
        >
          🗑
        </button>
      </div>
    ));
  };

  return (
    <div className="invoice-section">
      {invoiceItems.length > 0 && (
        <div className="invoice-header">
          <div>Item</div>
          <div>Description</div>
          <div>Quantity</div>
          <div>Unit Price</div>
          <div>Total Price</div>
        </div>
      )}
      {renderInvoiceItems()}
      <button className="add-row-button" onClick={addInvoiceItem}>
        + Add Row
      </button>
      <div className="invoice-footer">
        <div>Subtotal&nbsp;</div>
        <div>${calculateSubtotal().toFixed(2)}</div>
      </div>
      <div className="invoice-footer">
        <div>Tax Rate&nbsp;</div>
        <div>
          <input
            type="number"
            placeholder="Tax Rate (%)"
            value={tripModalData.taxRate}
            min="0"
            onChange={(e) =>
              setTripModalData({
                ...tripModalData,
                taxRate: parseFloat(e.target.value),
              })
            }
          />
          %
        </div>
      </div>
      <div className="invoice-footer">
        <div>Sales Tax&nbsp;</div>
        <div>
          $
          {calculateSalesTax(
            calculateSubtotal(),
            tripModalData.taxRate
          ).toFixed(2)}
        </div>
      </div>
      <div className="invoice-footer ">
        <div>Grand Total&nbsp;</div>
        <div>
          $
          {calculateGrandTotal(
            calculateSubtotal(),
            calculateSalesTax(calculateSubtotal(), tripModalData.taxRate)
          ).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

export default InvoiceSection;
