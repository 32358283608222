// This function is used to make a fetch request to the server
// it also handles the response status and redirects the user to the login page
// if the user is not authenticated
// callBackError should be fired if needed a specific action to be taken when an error occurs

export async function customFetch(url, options, callBackError) {
  const response = await fetch(url, options);
  if (!response.ok) {
    console.log("Response", response.status);
    const { status } = response;
    if (status === 401 || status === 403) {
      console.log("Status: ", status);
      // it means the user is not authenticated
      // redirect the user to the login page
      document.cookie = "oAuthCode=" + ""; //this will reset the cookie so we will prompt them at the login page
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
      if (callBackError) {
        callBackError(response);
      }
    }
    // const error = await response.json();
    // throw error;
  }
  return response;
}
