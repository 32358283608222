import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import { customFetch } from "../utils/customFetch.js";
import { getApiRoot } from "../utils/getApiRoot.js";
import InvoiceSection from "./invoicesection.js"; // Adjust the path based on your project structure
import "./tripmodal.css";

const Modal = ({
  resetTripModal,
  trips,
  setTrips,
  tripModalData,
  setTripModalData,
  showTripModal,
  closeModal,
  openAddCustomerModal
}) => {
  const [isClientNameFilled, setClientNameFilled] = useState(
    !!tripModalData.firstName && !!tripModalData.lastName
  );
  const [validationErrors, setValidationErrors] = useState({});
  const groupedDriverOptions = useRef(null);

  const fetchGroupedDriverOptions = async () => {
    if (!groupedDriverOptions.current) {
      const data = await getGroupedDriverOptions();
      groupedDriverOptions.current = data;
    }
    return groupedDriverOptions;
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true
    };
    return currentDate.toLocaleString("en-US", options);
  };

  const handleClientSelection = (selection) => {
    if (selection.value && selection.value == "addCustomer") {
      closeModal();
      openAddCustomerModal();
    } else if (selection.value) {
      setTripModalData((prevData) => ({
        ...prevData,
        ...selection.value,
        quoteDate: getCurrentDate()
      }));
      setClientNameFilled(true);
    } else {
      setTripModalData((prevData) => {
        const { phoneNumber, companyName, email, ...nonCustomerFields } = prevData;
        return {
          ...nonCustomerFields,
          phoneNumber: "",
          companyName: "",
          email: ""
        };
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value) && value < 0) {
      return;
    }
    setTripModalData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCheckboxChange = () => {
    setTripModalData((prevData) => ({
      ...prevData,
      disableCompanyName: !prevData.disableCompanyName
    }));
  };

  const handleCustomerTypeChange = (contract) => {
    setTripModalData((prevData) => ({ ...prevData, contract }));
  };

  const notifyDriver = async () => {
    const options = {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        tripId: tripModalData.tripId,
        driverEmail: tripModalData.driverEmail,
        driverName: tripModalData.driverName,
        departureDate: tripModalData.departureDate,
        returnDate: tripModalData.returnDate
      })
    };
    const response = await customFetch(`${getApiRoot()}/api/notifydriver`, options);
    if (!response.ok) {
      alert("Trip was not fully saved due to a temporary server error. Please try saving again.");
    }
  };

  const getBusDrivers = async () => {
    if (tripModalData.tripStatus === "Accepted") {
      const departureISO = new Date(tripModalData.departureDate).toISOString();
      const returnISO = new Date(tripModalData.returnDate).toISOString();
      const options = { credentials: "include" };
      const response = await customFetch(
        `${getApiRoot()}/api/busdrivers?departureDate=${departureISO}&returnDate=${returnISO}`,
        options
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const drivers = await response.json();
      return drivers;
    } else {
      return [];
    }
  };

  const getPrimaryDriverOptions = (drivers) => {
    const uniquePrimaryDriverNames = new Set();
    return drivers
      .filter((driver) => {
        if (driver.available && driver.primary && !uniquePrimaryDriverNames.has(driver.name)) {
          uniquePrimaryDriverNames.add(driver.name);
          return true;
        }
        return false;
      })
      .map((driver) => ({
        label: driver.name,
        value: driver
      }));
  };

  const getSecondaryDriverOptions = (drivers) => {
    const uniqueSecondaryDriverNames = new Set();
    return drivers
      .filter((driver) => {
        if (driver.available && driver.secondary && !uniqueSecondaryDriverNames.has(driver.name)) {
          uniqueSecondaryDriverNames.add(driver.name);
          return true;
        }
        return false;
      })
      .map((driver) => ({
        label: driver.name,
        value: driver
      }));
  };

  const getUnavailableDriverOptions = (drivers) => {
    const uniqueUnavailableDriverNames = new Set();
    return drivers
      .filter((driver) => {
        if (!driver.available && !uniqueUnavailableDriverNames.has(driver.name)) {
          uniqueUnavailableDriverNames.add(driver.name);
          return true;
        }
        return false;
      })
      .map((driver) => ({
        label: driver.name,
        value: driver
      }));
  };

  const getGroupedDriverOptions = async () => {
    const drivers = await getBusDrivers();
    return [
      {
        label: "Primary Drivers",
        options: getPrimaryDriverOptions(drivers)
      },
      {
        label: "Secondary Drivers",
        options: getSecondaryDriverOptions(drivers)
      },
      {
        label: "Unavailable Drivers",
        options: getUnavailableDriverOptions(drivers)
      }
    ];
  };

  const getBuses = async () => {
    const departureISO = new Date(tripModalData.departureDate).toISOString();
    const returnISO = new Date(tripModalData.returnDate).toISOString();
    const options = { credentials: "include" };
    const response = await customFetch(
      `${getApiRoot()}/api/buses?departureDate=${departureISO}&returnDate=${returnISO}`,
      options
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const buses = await response.json();
    return buses;
  };

  const getAvailableBusOptions = (buses) => {
    return buses
      .filter((bus) => bus.available)
      .map((bus) => ({
        label: bus.resourceName,
        value: bus
      }));
  };

  const getUnavailableBusOptions = (buses) => {
    return buses
      .filter((bus) => !bus.available)
      .map((bus) => ({
        label: bus.resourceName,
        value: bus
      }));
  };

  const getGroupedBusOptions = async () => {
    const buses = await getBuses();
    const availableBuses = getAvailableBusOptions(buses);
    const unavailableBuses = getUnavailableBusOptions(buses);
    const groupedOptions =[
      {
        label: "Available Buses",
        options: availableBuses
      },
      {
        label: "Unavailable Buses",
        options: unavailableBuses
      }
    ];

    return groupedOptions;
  };

  // when the save and notify button is clicked by user, the selected bus will be sent to the backend to save to the database and added to the google calendar event
  const saveBusSelection = async () => {
    const departureISO = new Date(tripModalData.departureDate).toISOString();
    const returnISO = new Date(tripModalData.returnDate).toISOString();

    try {
      const options = {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          selectedBus: tripModalData?.selectedBus,
          busName: tripModalData?.resourceName,
          tripId: tripModalData?.tripId
        })
      };

      const response = await customFetch(
        `${getApiRoot()}/api/update-bus-in-trip?departureDate=${departureISO}&returnDate=${returnISO}`,
        options
      );

      if (response.ok) {
        resetTripModal();
        closeModal();
      } else{
        alert("Trip was not saved due to a temporary server error. Please try saving again.");
      }


    } catch (error) {
      alert("Trip was not saved due to a temporary server error. Please try saving again.");
      // Handle error
    }
  };

  const getClients = async (inputValue) => {
    let clientResults = [];

    if (inputValue) {
      const customFetchOptions = {
        credentials: "include",
        headers: {
          "content-type": "application/json"
        }
      };

      let clientResponse = await customFetch(
        `${getApiRoot()}/api/customers/search?lastName=${inputValue}`,
        customFetchOptions
      );

      clientResults = await clientResponse.json();

      if (clientResults.length == 0) {
        clientResponse = await customFetch(
          `${getApiRoot()}/api/customers/search?firstName=${inputValue}`,
          customFetchOptions
        );
        clientResults = await clientResponse.json();
      }

      clientResults = clientResults.map((clientResult) => {
        return {
          label: `${clientResult.firstName} ${clientResult.lastName}`,
          value: clientResult
        };
      });
    }

    clientResults = clientResults.filter((clientResult) =>
      clientResult.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    clientResults.push({ label: "Add New Customer", value: "addCustomer" });
    console.log("clientResult", clientResults);
    return clientResults;
  };

  // modify the validateForm function to accept a parameter indicating if the form is a draft or a complete trip submission
  const validateForm = (isDraft) => {
    const errors = {};

    // validate the required fields baseed on the form type
    const requiredFields = isDraft
      ? ["firstName", "lastName", "quoteDate", "phoneNumber", "email"]
      : [
        // Add all other fields that are required for a full submission
        "firstName",
        "lastName",
        "quoteDate",
        "phoneNumber",
        "email",
        "quoteDate",
        "tripStatus",
        "paymentStatus",
        "groupName",
        "groupPhone",
        "groupLeader",
        "numOfPassengers",
        "pickupLocation",
        "destination",
        "departureDate",
        "returnDate",
        "estimatedMileage"
      ];

    // additional logic for client name filled status
    if (!isDraft) {
      if (!isClientNameFilled) {
        errors.clientName = "*Required";
      }
    }

    requiredFields.forEach((field) => {
      if (!tripModalData[field]) {
        errors[field] = `*Required`;
      }
    });

    setValidationErrors(errors);

    return Object.keys(errors).length === 0; // Form is valid if no errors
  };

  // Helper function to handle common logic for saving drafts or full trip submissions
  const handleTripSubmission = async (isDraft) => {
    // Validate form
    const isValidForm = validateForm(isDraft);

    // Add validation to check if Departure Date is after Return Date
    const departureDate = new Date(tripModalData.departureDate);
    const returnDate = new Date(tripModalData.returnDate);

    if (departureDate > returnDate) {
      alert("Error: Departure Date cannot be after Return Date");
      return; // Prevent form submission
    }

    // Check if it's a draft and update the tripStatus accordingly
    const tripStatus = isDraft ? "Draft" : "Pending";

    // Continue with form submission logic if the form is valid
    if (isValidForm) {
      const apiRoot = getApiRoot();

      // Determine the HTTP method based on whether it's a draft
      const method = tripStatus === "Draft" ? "PATCH" : tripModalData.tripId ? "PUT" : "POST";
      const customFetchBody = tripModalData;
      if (!customFetchBody.tripStatus) {
        customFetchBody.tripStatus = isDraft ? "Draft" : "Pending";
      }

      // You can make an API call, update state, etc.
      let options = {
        method: method,
        credentials: "include",
        body: JSON.stringify(customFetchBody),
        headers: {
          "Content-Type": "application/json"
        }
      };

      // if (tripModalData.tripId) {
      //   // This was an update, not a new record
        let updatedTrips = trips.map((trip) => {
          if (trip.tripId === tripModalData.tripId) {
            trip = tripModalData; // Update the grid
          }
          return trip;
        });
        setTrips(updatedTrips);
      // }

      try {
        const addTripResponse = await customFetch(`${apiRoot}/api/trips`, options);

        if (addTripResponse.status === 409) {
          alert("A trip with the same details already exists");
        } else if (addTripResponse.status === 200) {
          resetTripModal();
          closeModal();
        } else {
          alert(`${addTripResponse.status} Error ${isDraft ? "saving draft" : "adding trip"}.`);
        }
      } catch (error) {
        console.error(`Error ${isDraft ? "saving draft" : "adding trip"}:`, error);
        // Handle error appropriately, e.g., show an error message
        alert(`An unexpected error occurred while ${isDraft ? "saving draft" : "adding trip"}.`);
      }

      // Reset validation errors after successful submission
      setValidationErrors({});
    } else {
      // Display banner at the top of the document to prompt the user to complete all the required fields
      document.getElementById("validationBanner").style.display = "block";
    }
  };

  // Modify the handleSaveDraft and handleAddTrip functions to pass the form type
  const handleSaveDraft = () => {
    handleTripSubmission(true); // Pass true to indicate it's a draft
  };

  const updateTrip = () => {
    handleTripSubmission(false); // Pass false to indicate it's not a draft
  };

  return (
    <div className={`modal-overlay ${showTripModal ? "show" : "hide"} `} onClick={closeModal}>
      <div className="modal-content " onClick={(e) => e.stopPropagation()}>
        <div className="CQmodal-header">
          {/* validation errors banner */}
          <div id="validationBanner">Please enter all required fields to save a trip.</div>
          <div></div>
          <h2>CHARTER ESTIMATE</h2>
          <button className="modal-close-btn" onClick={closeModal}>
            X
          </button>
        </div>
        <div className="CQmodal-body">
          <div className="bigSection ">
            <div className="sectionTitle ">Client&apos;s Information</div>
            <div className="row">
              <label>
                Client&apos;s Name
                {tripModalData.tripId ? (
                  <input
                    readOnly={true}
                    type="text"
                    name="clientName"
                    placeholder="First & Last"
                    value={`${tripModalData.firstName} ${tripModalData.lastName}`}
                  />
                ) : (
                  <div>
                    <AsyncSelect
                      defaultOptions={[{ label: "Type a customer name", value: "" }]}
                      loadOptions={getClients}
                      onChange={(selection) => {
                        handleClientSelection(selection);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: "350px",
                          borderRadius: "8px",
                          height: "40px",
                          borderColor: validationErrors.firstName ? "red" : ""
                        })
                      }}
                    />
                    <div
                      className="error-message"
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        width: "100%"
                      }}>
                      {validationErrors.clientName}
                    </div>
                  </div>
                )}
              </label>
            </div>
            <div className="row">
              <label>
                Customer&apos;s Phone
                <input
                  readOnly={true}
                  type="text"
                  name="phoneNumber"
                  className={validationErrors.phoneNumber ? "error" : ""}
                  placeholder="(XXX) XXX XXXX"
                  value={tripModalData.phoneNumber}
                  onChange={handleInputChange}
                  onBlur={() => {
                    console.log("Email lost focus");
                  }}
                />
                {validationErrors.phoneNumber && (
                  <div className="error-message">{validationErrors.phoneNumber}</div>
                )}
              </label>
              <label>
                Quote Date
                <input
                  readOnly={true}
                  type="text"
                  name="quoteDate"
                  className={validationErrors.quoteDate ? "error" : ""}
                  placeholder="MM/DD/YYYY"
                  value={tripModalData.quoteDate}
                  onChange={handleInputChange}
                />
                {validationErrors.quoteDate && (
                  <div className="error-message">{validationErrors.quoteDate}</div>
                )}
              </label>
            </div>
            <div className="row">
              <label>
                Customer&apos;s Email:
                <input
                  readOnly={true}
                  type="text"
                  name="email"
                  className={validationErrors.email ? "error" : ""}
                  placeholder="example@email.com"
                  value={tripModalData.email}
                  onChange={handleInputChange}
                  onBlur={() => {
                    console.log("Email lost focus");
                  }}
                />
                {validationErrors.email && (
                  <div className="error-message">{validationErrors.email}</div>
                )}
              </label>
              <label>
                Company Name
                <input
                  readOnly={true}
                  type="text"
                  name="companyName"
                  className="companyName"
                  placeholder="Company Name"
                  value={tripModalData.companyName}
                  onChange={handleInputChange}
                />
              </label>
            </div>
            <div className="row">
              <label>
                Trip Status
                <select
                  name="tripStatus"
                  value={tripModalData.tripStatus}
                  onChange={handleInputChange}
                  className={"tripStatusSelect"}
                  placeholder={"Pending"}>
                  <option value="Pending">Pending</option>
                  <option value="Draft">Draft</option>
                  <option value="Accepted">Accepted</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Complete">Complete</option>
                </select>
              </label>
              <label>
                Payment Status
                <select
                  name="paymentStatus"
                  value={tripModalData.paymentStatus}
                  onChange={handleInputChange}
                  className={"tripStatusSelect"}
                  placeholder={"Unpaid"}>
                  <option value="Unpaid">Unpaid</option>
                  <option value="Paid">Paid</option>
                  <option value="Deposite">Deposite</option>
                  <option value="Refunded">Refunded</option>
                </select>
              </label>
            </div>
          </div>
          <div className="bigSection">
            <div className="sectionTitle">Trip Details</div>
            <div className="row">
              <label>
                Group Name
                <input
                  type="text"
                  name="groupName"
                  className={validationErrors.groupName ? "error" : ""}
                  placeholder="Group Name"
                  value={tripModalData.groupName}
                  onChange={handleInputChange}
                />
                {validationErrors.groupName && (
                  <div className="error-message">{validationErrors.groupName}</div>
                )}
              </label>
              <label>
                Group Phone
                <input
                  type="text"
                  name="groupPhone"
                  className={validationErrors.groupPhone ? "error" : ""}
                  placeholder="(XXX) XXX XXXX)"
                  value={tripModalData.groupPhone}
                  onChange={handleInputChange}
                />
                {validationErrors.groupPhone && (
                  <div className="error-message">{validationErrors.groupPhone}</div>
                )}
              </label>
            </div>
            <div className="row">
              <label>
                Group Leader
                <input
                  type="text"
                  name="groupLeader"
                  className={validationErrors.groupLeader ? "error" : ""}
                  placeholder="Group Leader"
                  value={tripModalData.groupLeader}
                  onChange={handleInputChange}
                />
                {validationErrors.groupLeader && (
                  <div className="error-message">{validationErrors.groupLeader}</div>
                )}
              </label>
              <label>
                Number of Passengers
                <input
                  type="number"
                  name="numOfPassengers"
                  className={validationErrors.numOfPassengers ? "error" : ""}
                  placeholder="Number of Passengers"
                  value={tripModalData.numOfPassengers}
                  onChange={handleInputChange}
                  min="0"
                />
                {validationErrors.numOfPassengers && (
                  <div className="error-message">{validationErrors.numOfPassengers}</div>
                )}
              </label>
            </div>
            <div className="row">
              <label>
                Pickup Location
                <input
                  type="text"
                  name="pickupLocation"
                  className={validationErrors.pickupLocation ? "error" : ""}
                  placeholder="Pickup Location"
                  value={tripModalData.pickupLocation}
                  onChange={handleInputChange}
                />
                {validationErrors.pickupLocation && (
                  <div className="error-message">{validationErrors.pickupLocation}</div>
                )}
              </label>
              <label>
                Destination
                <input
                  type="text"
                  name="destination"
                  className={validationErrors.destination ? "error" : ""}
                  placeholder="Destination"
                  value={tripModalData.destination}
                  onChange={handleInputChange}
                />
                {validationErrors.destination && (
                  <div className="error-message">{validationErrors.destination}</div>
                )}
              </label>
            </div>
          </div>
          <div className="bigSection">
            <div className="row">
              <label>
                Departure Date & Time
                <DatePicker
                  selected={tripModalData.departureDate}
                  onChange={(date) =>
                    setTripModalData((prevData) => ({
                      ...prevData,
                      departureDate: date
                    }))
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  placeholderText="Departure Date & Time"
                  className={validationErrors.departureDate ? "error" : ""}
                />
                {validationErrors.departureDate && (
                  <div className="error-message">{validationErrors.departureDate}</div>
                )}
              </label>
              <label>
                Return Date & Time
                <DatePicker
                  selected={tripModalData.returnDate}
                  onChange={(date) =>
                    setTripModalData((prevData) => ({
                      ...prevData,
                      returnDate: date
                    }))
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  placeholderText="Return Date & Time"
                  className={validationErrors.returnDate ? "error" : ""}
                  yearDropdownItem={null} // Add this line to disable year dropdown
                />
                {validationErrors.returnDate && (
                  <div className="error-message">{validationErrors.returnDate}</div>
                )}
              </label>
            </div>
            <div className="row">
              <label>
                Estimated Mileage
                <input
                  type="text"
                  name="estimatedMileage"
                  className={validationErrors.estimatedMileage ? "error" : ""}
                  placeholder="Estimated Mileage"
                  value={tripModalData.estimatedMileage}
                  onChange={handleInputChange}
                />
                {validationErrors.estimatedMileage && (
                  <div className="error-message">{validationErrors.estimatedMileage}</div>
                )}
              </label>
              <label>
                Other Info
                <input
                  type="text"
                  name="otherInfo"
                  placeholder="Other Info"
                  value={tripModalData.otherInfo}
                  onChange={handleInputChange}
                  onBlur={() => {
                    console.log("Email lost focus");
                  }}
                />
              </label>
            </div>

            {tripModalData.tripStatus === "Accepted" && (
              <div className="row">
                <label>
                  Driver Name
                  <div>
                    <AsyncSelect
                      value={
                        tripModalData.driverName
                          ? {
                            value: tripModalData.driverName,
                            label: tripModalData.driverName
                          }
                          : null
                      }
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          // Extract driver name and email from selectedOption.value
                          const { name, email } = selectedOption.value;

                          // Update tripModalData with extracted driver name and email
                          setTripModalData((prevData) => ({
                            ...prevData,
                            driverName: name,
                            driverEmail: email
                          }));
                        } else {
                          // If no driver is selected, clear driverName and driverEmail
                          setTripModalData((prevData) => ({
                            ...prevData,
                            driverName: "",
                            driverEmail: ""
                          }));
                        }
                      }}
                      loadOptions={getGroupedDriverOptions}
                      defaultOptions
                    />
                  </div>
                </label>

                <label>
                  Bus
                  <div>
                    <AsyncSelect
                      // Props for bus dropdown menu
                      value={tripModalData.selectedBus ? {value:tripModalData.selectedBus, label:tripModalData.selectedBus.resourceName}:null}
                      loadOptions={getGroupedBusOptions}
                      // getOptionLabel={(bus) => bus.resourceName} // Use resourceName to display bus name
                      // getOptionValue={(bus) => bus.resourceEmail} // Assuming bus objects have an id property
                      // defaultValue={tripModalData.selectedBus} // Set default value if needed
                      onChange={(selectedOption) => {
                        // Update tripModalData with selected bus
                        setTripModalData((prevData) => ({
                          ...prevData,
                          selectedBus: selectedOption?.value,
                          busName: selectedOption?.value?.resourceName,
                        }));
                      }}
                      defaultOptions
                    />
                  </div>
                </label>
              </div>
            )}
          </div>
          <div className="row">
            <label>
              Notes
              <textarea
                name="notes"
                className="tripNotesInput"
                placeholder="Write here all your notes..."
                value={tripModalData.notes}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <InvoiceSection tripModalData={tripModalData} setTripModalData={setTripModalData} />
        </div>

        <div className="modal-footer">
          <button
            className="button-CANCEL"
            onClick={() => {
              resetTripModal();
              closeModal();
            }}>
            CANCEL
          </button>
          <button className="button-SAVEDRAFT" onClick={handleSaveDraft}>
            SAVE DRAFT
          </button>
          <button
            className="button-ADD"
            onClick={() => {
              updateTrip();//fields may have changed on trip, so save it
              tripModalData.driverName && notifyDriver();//notify driver if driver is selected
              tripModalData.selectedBus && saveBusSelection();//save bus selection if bus is selected
            }}>
            {tripModalData.driverName ? "SAVE AND NOTIFY DRIVER" : "SAVE AND SEND QUOTE"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  resetTripModal: PropTypes.func.isRequired,
  trips: PropTypes.array.isRequired,
  setTrips: PropTypes.func.isRequired,
  tripModalData: PropTypes.object.isRequired,
  setTripModalData: PropTypes.func.isRequired,
  showTripModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openAddCustomerModal: PropTypes.func.isRequired
};
