import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./images/logoWhite.png";
import sidebarcss from "./sidebar.module.css";

const Sidebar = ({ authorization }) => {
  const [isShowButton, setIsShowButton] = useState(false);
  const navigator = useNavigate();
  return (
    <div className={sidebarcss.sidebar}>
      {/* Logo and Toggle Icon */}
      <div className={sidebarcss.sidebarContainer}>
        <div className={sidebarcss.sidebarContainerLogo}>
          <img src={logo} alt="Mustang Transportation logo" className={sidebarcss.logo} />
        </div>
        <div className={sidebarcss.sidebarContainerPages}>
          <div className={sidebarcss.sidebarContainerPage}>
            <div className={sidebarcss.pageIcon}>🌐</div>
            <div className={sidebarcss.pageTitle}>Trips</div>
          </div>
        </div>
      </div>

      {/* User Info */}
      <div
        onBlur={() => {
          setIsShowButton(false);
        }}
        tabIndex="0">
        {isShowButton && (
          <button
            className={sidebarcss.logout}
            onMouseDown={() => {
              navigator("/logout");
            }}>
            Logout
          </button>
        )}
        <div
          className={sidebarcss.sidebarContainerUser}
          onClick={() => {
            setIsShowButton(!isShowButton);
          }}>
          <div className={sidebarcss.userCircle}>👤</div>
          <div className={sidebarcss.userInfo}>
            <div className={sidebarcss.userName}>
              {authorization &&
                authorization.emailAddress &&
                authorization.emailAddress.split("@")[0]}
            </div>
            <div className={sidebarcss.userEmail}>
              {authorization && authorization.emailAddress}
            </div>
          </div>
          ...
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  authorization: PropTypes.object
};
